import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Button, FormInput, FormRow, InputButtonList } from 'fogg/ui';
import {
  FaChevronLeft,
  FaCheck,
  FaPlusCircle,
  FaCloudUploadAlt
} from 'react-icons/fa';

import SidebarHeader from './SidebarHeader';
import SidebarBody from './SidebarBody';
import SidebarFooter from './SidebarFooter';
import SidebarActions from 'components/SidebarActions';

import { timezoneOptions } from 'data/timezones';

import aoi from 'assets/images/aoi.png';

const SidebarSettings = ({ setVisibleSidebar, event = {} }) => {
  const {
    name,
    startDate,
    endDate,
    eventStartDate,
    eventEndDate,
    timezoneOffset
  } = event;

  function handleCloseSettings () {
    setVisibleSidebar('layers');
  }

  const actions = [
    {
      label: 'Save Settings',
      icon: <FaCheck />,
      onClick: handleCloseSettings
    }
  ];

  return (
    <>
      <SidebarHeader>
        <p className="sidebar-header-back">
          <Button type="text" full={true} onClick={handleCloseSettings}>
            <FaChevronLeft /> Back to Layers
          </Button>
        </p>
        <h1>Settings</h1>
      </SidebarHeader>

      <SidebarBody className="full">
        <form className="settings">
          <div className="sidebar-body-heading">
            <p>Event Settings</p>
          </div>

          <Panel className="settings-panel">
            <FormRow>
              <FormInput id="name" label="Event Name" value={name} />
            </FormRow>
            <FormRow>
              <FormInput
                id="eventStartDate"
                label="Event Start Date"
                type="datetime"
                value={eventStartDate}
              />
              {eventEndDate && (
                <FormInput
                  id="eventEndDate"
                  label="Event End Date"
                  type="datetime"
                  value={eventEndDate}
                />
              )}
            </FormRow>
            <FormRow>
              <FormInput
                id="startDate"
                label="Start Date"
                type="datetime"
                value={startDate}
              />
              {endDate && (
                <FormInput
                  id="endDate"
                  label="End Date"
                  type="datetime"
                  value={endDate}
                />
              )}
            </FormRow>
            <FormRow>
              <FormInput
                label="Timezone"
                type="select"
                name="timezone_offset"
                id="timezone-offset"
                options={timezoneOptions}
                defaultValue={timezoneOffset}
              />
            </FormRow>

            <FormRow>
              <div className="form-input">
                <label>Area of Interest</label>
                <img src={aoi} alt="aoi" className="aoi" />
              </div>
            </FormRow>

            <FormRow>
              <div>
                <InputButtonList
                  label="Layers"
                  name="layers"
                  type="checkbox"
                  options={[
                    {
                      label: 'VIIRS',
                      value: 'viirs',
                      isChecked: true
                    },
                    {
                      label: 'Fire Boundary',
                      value: 'fire-boundary',
                      isChecked: true
                    },
                    {
                      label: 'Reported Events',
                      value: 'reported-events',
                      isChecked: true
                    },
                    {
                      label: 'Coastlines',
                      value: 'coastlines'
                    }
                  ]}
                  required={true}
                />
                <p>
                  <Button
                    type={['text', 'icon-before']}
                    full={true}
                    disabled={true}
                  >
                    <FaPlusCircle /> Add New Layer
                  </Button>
                </p>
              </div>
            </FormRow>

            <FormRow>
              <div>
                <InputButtonList
                  label="3rd Party Stacks"
                  name="stacks"
                  type="checkbox"
                  options={[
                    {
                      label: 'FilmDrop (L0 Processing)',
                      value: 'filmdrop',
                      isChecked: true
                    },
                    {
                      label: 'Maxar XTerrain',
                      value: 'maxar-xterrain'
                    },
                    {
                      label: 'Mapbox Atlas',
                      value: 'mapbox-atlas'
                    }
                  ]}
                  required={true}
                />
                <p>
                  <Button
                    type={['text', 'icon-before']}
                    full={true}
                    disabled={true}
                  >
                    <FaPlusCircle /> Add Stack
                  </Button>
                </p>
              </div>
            </FormRow>

            <FormRow>
              <InputButtonList
                label="AWS Snowball Configuration"
                name="configuration"
                type="checkbox"
                options={[
                  {
                    label: 'Include All Zoom Levels',
                    value: 'all-zoom-levels',
                    isChecked: true
                  },
                  {
                    label: 'Process Pre-Event Data',
                    value: 'maxar-xterrain',
                    isChecked: true
                  }
                ]}
                required={true}
              />
            </FormRow>
          </Panel>

          <FormRow>
            <p>
              <Button type="icon-before" full={true} disabled={true}>
                <FaCloudUploadAlt /> Provision Snowball
              </Button>
            </p>
          </FormRow>
        </form>
      </SidebarBody>

      <SidebarFooter>
        <div className="sidebar-section">
          <SidebarActions actions={actions} />
        </div>
      </SidebarFooter>
    </>
  );
};

SidebarSettings.propTypes = {
  setVisibleSidebar: PropTypes.func,
  event: PropTypes.object
};

export default SidebarSettings;
