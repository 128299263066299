import React from 'react';
import PropTypes from 'prop-types';
import LayerToggle from 'components/LayerToggle';

import ClassName from 'models/classname';

const LayerList = ({ layers = [], heading, toggleLayer }) => {
  const componentClassName = new ClassName('layer-list');

  if (!Array.isArray(layers) || layers.length === 0) {
    return null;
  }

  return (
    <div className={componentClassName.string}>
      <p className={componentClassName.child('heading')}>{heading}</p>
      <ul>
        {layers.map((layer, index) => (
          <li key={`SidebarLayersLayer-${index}`}>
            <LayerToggle layer={layer} onClick={() => toggleLayer(layer.id)} />
          </li>
        ))}
      </ul>
    </div>
  );
};

LayerList.propTypes = {
  layers: PropTypes.array,
  heading: PropTypes.string,
  toggleLayer: PropTypes.func
};

export default LayerList;
