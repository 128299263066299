import React from 'react';
import PropTypes from 'prop-types';

const HoverBox = ({
  datapoint = {},
  show,
  xPosition,
  timelinePosition = 0
}) => {
  // timelinePosition is a negative value representing how far it's been dragged
  const styles = {
    left: `${xPosition + timelinePosition}px`
  };
  const { properties = {}, geometry = {} } = datapoint || {};
  const { metadata = {}, time, reporter } = properties;
  const { name, species, notes, contact } = metadata;
  const { coordinates = [] } = geometry;
  return (
    <>
      {show && (
        <div className="hover-box" style={styles}>
          <div className="hover-box-inner" style={styles}>
            <header className="hover-box-header">
              <h2 className="hover-box-title">{properties.type}</h2>
              <span className="hover-box-meta">
                {coordinates[1]}, {coordinates[0]}
              </span>
            </header>
            <div className="hover-box-body">
              {name && (
                <span className="hover-box-item">
                  <b>Name:</b> {name}
                </span>
              )}
              {species && (
                <span className="hover-box-item">
                  <b>Species:</b> {species}
                </span>
              )}
            </div>
            <div className="hover-box-footer">
              {notes && (
                <span className="hover-box-item">
                  <b>Notes:</b> {notes}
                </span>
              )}
              {contact && (
                <span className="hover-box-item">
                  <b>Contact:</b> {contact}
                </span>
              )}
              {time && (
                <span className="hover-box-item">
                  <b>Reported:</b> {time}
                </span>
              )}
              {reporter && (
                <span className="hover-box-item">
                  <b>Reporter:</b> {reporter}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

HoverBox.propTypes = {
  datapoint: PropTypes.object,
  show: PropTypes.bool,
  xPosition: PropTypes.number,
  timelinePosition: PropTypes.number
};

export default HoverBox;
