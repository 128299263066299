import React from 'react';
import PropTypes from 'prop-types';

const Toggle = ({ isChecked, readOnly = true }) => {
  const inputSettings = {
    readOnly
  };

  // Controlled inputs require read only. If we want full control, then
  // that's designated by the readOnly prop. If we want it to simply be
  // checked by default, we would pass readOnly as false

  if (inputSettings.readOnly) {
    inputSettings.checked = !!isChecked;
  } else {
    inputSettings.defaultChecked = !!isChecked;
  }

  return (
    <span className="toggle" data-is-toggle-active={isChecked}>
      <input className="visually-hidden" type="checkbox" {...inputSettings} />
      <span className="toggle-switch" />
    </span>
  );
};

Toggle.propTypes = {
  isChecked: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default Toggle;
