import { useRef, useCallback, useEffect, useState } from 'react';

export default function useDraggable ({
  onDrag,
  onDragStart,
  onDragEnd,
  startingPosition,
  minX,
  disableDragOnMouseOut = false,
  name
}) {
  const requestRef = useRef();

  const initialPosition = {
    x: startingPosition.x || 0,
    y: startingPosition.y || 0
  };

  const [draggableState, setDraggableState] = useState({
    isDragging: false,
    isDraggable: true,
    position: initialPosition,
    startPosition: initialPosition,
    origin: {
      x: 0
    },
    width: 0,
    onDragEnd,
    onDragStart
  });

  const toggleBodySelectionDisable = disabled => {
    if (disabled) {
      document.body.classList.add('no-select');
      return;
    }

    document.body.classList.remove('no-select');
  };

  const handleMouseDown = useCallback(
    ({ clientX }) => {
      setDraggableState(state => ({
        ...state,
        isDragging: true,
        origin: { x: clientX },
        startPosition: state.position
      }));

      if (onDragStart) onDragStart();

      toggleBodySelectionDisable(true);
    },
    [setDraggableState, onDragStart]
  );

  const handleMouseMove = useCallback(
    ({ clientX }) => {
      requestRef.current = requestAnimationFrame(() => {
        if (draggableState.isDraggable) {
          setDraggableState(state => {
            // Prevent the value from being set lower than 0.
            // This prevents panning past the start date.
            let nextPositionX =
              state.startPosition.x - (state.origin.x - clientX);

            if (minX !== undefined) {
              nextPositionX = nextPositionX < minX ? nextPositionX : minX;
            }

            const nextState = {
              ...state,
              position: {
                x: nextPositionX
              }
            };

            if (onDrag) onDrag(nextState.position);

            return nextState;
          });
        }
      });

      return () => cancelAnimationFrame(requestRef.current);
    },
    [draggableState.isDraggable, minX]
  );

  const handleMouseOut = useCallback(() => {
    if (disableDragOnMouseOut) {
      setDraggableState(state => {
        if (onDragEnd && state.position) onDragEnd(state.position);

        return {
          ...state,
          position: state.position,
          isDragging: false
        };
      });

      toggleBodySelectionDisable(false);
    }
  }, [onDragEnd, setDraggableState, disableDragOnMouseOut]);

  const handleMouseUp = useCallback(() => {
    setDraggableState(state => {
      if (onDragEnd && state.position) {
        onDragEnd(state.position);
      }

      return {
        ...state,
        position: state.position,
        isDragging: false
      };
    });

    toggleBodySelectionDisable(false);
  }, [onDragEnd, setDraggableState]);

  useEffect(() => {
    if (draggableState.isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('mouseout', handleMouseOut);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mouseout', handleMouseOut);

      setDraggableState(state => ({ ...state, translation: { x: 0 } }));
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mouseout', handleMouseOut);
    };
  }, [
    draggableState.isDragging,
    handleMouseMove,
    handleMouseUp,
    handleMouseOut
  ]);

  return [handleMouseDown, draggableState, setDraggableState];
}
