import React from 'react';
import PropTypes from 'prop-types';

const SidebarBody = ({ children, className }) => (
  <div className={`sidebar-body ${className}`}>{children}</div>
);

SidebarBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default SidebarBody;
