import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';
import { addDatapoint } from '../state/actions';
import FormCreateDatapoint from './FormCreateDatapoint';

const MapPopupContent = ({
  children,
  className = '',
  layer = {},
  hideMarker,
  ...rest
}) => {
  const dispatch = useDispatch();

  function handleSubmit (e, datapoint) {
    const formSubmit = new Event('datapointFormSubmit');
    window.dispatchEvent(formSubmit);

    if (typeof hideMarker === 'function') {
      hideMarker();
    }

    dispatch(addDatapoint(datapoint));
    e.target.reset();
  }

  return (
    <div className={`popup-content ${className}`}>
      <FormCreateDatapoint
        onSubmit={handleSubmit}
        layer={layer}
        hideMarker={hideMarker}
        {...rest}
      />
      {children}
    </div>
  );
};

MapPopupContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  layer: PropTypes.object,
  hideMarker: PropTypes.func
};

export default MapPopupContent;
