import moment from 'moment';

export default function enumerate (startDate, endDate, interval) {
  const dates = [startDate.clone().toDate()];

  const currDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss').startOf(interval);
  const lastDate = moment(endDate, 'YYYY-MM-DD HH:mm:ss').startOf(interval);

  let count = 0;

  while (currDate.add(1, `${interval}s`).diff(lastDate, true) < 0) {
    count = count + 1;
    dates.push(currDate.clone().toDate());
  }

  dates.push(endDate.clone().toDate());

  return dates;
}
