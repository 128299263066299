import moment from 'moment-timezone';
import axios from 'axios';
import {
  insituPointToLayer,
  viirsPointToLayer,
  viirsToGeoJson
} from '../lib/util';

export default class Event {
  constructor (data = {}) {
    this.name = data.name;
    this.logo = data.logo;
    this.slug = data.slug;
    this.timezone = data.timezone;
    this.timezoneOffset = data.timezoneOffset;
    this.startDate = this.dateToMoment(data.startDate);
    this.endDate = data.endDate ? this.dateToMoment(data.endDate) : null;
    this.eventStartDate = data.eventStartDate
      ? this.dateToMoment(data.eventStartDate)
      : null;
    this.eventEndDate = data.eventEndDate
      ? this.dateToMoment(data.eventEndDate)
      : null;
    this.duration = this.calculateDuration(this.startDate, this.endDate);
    this.layers = Object.assign(
      {
        base: null,
        overlay: null
      },
      data.layers
    );
    this.basemapDate = this.getBasemapDate();
    this.services = this.setServices(data.services);
    this.defaultCenter = data.defaultCenter || {
      lat: 39.52052,
      lng: -122.19818
    };
    this.projection = data.projection || 'epsg3857';
    this.defaultZoom = data.defaultZoom || 8;
    this.maxZoom = data.maxZoom;
    this.minZoom = data.minZoom;
    this.fetchLayerData = this.setFetchLayerData(data.fetchLayerData);
  }

  calculateDuration (startDate, endDate) {
    if (!endDate) return null;
    return moment.duration(endDate.diff(startDate));
  }

  dateToMoment (date) {
    return moment.tz(date, this.timezone);
  }

  formatDate (date) {
    return date.format('MMMM DD, YYYY');
  }

  formatTime (date) {
    return date.format('HH:mm:ss');
  }

  getBasemapDate () {
    const mapDate = this.endDate || this.startDate;
    return mapDate.format('YYYY-MM-DD');
  }

  setServices (services = []) {
    return services.map(service => {
      return {
        ...service,
        time: service.time || this.basemapDate
      };
    });
  }

  setFetchLayerData (data) {
    if (!Array.isArray(data)) return;
    return data.map(item => {
      const { endpoint, name, type = 'geojson', options } = item;
      const { pointToLayer: pointToLayerOption, format } = options || {};

      return async () => {
        const response = await axios(endpoint);
        let pointToLayer;
        let data = response.data;

        switch (pointToLayerOption) {
          case 'insituPointToLayer':
            pointToLayer = insituPointToLayer;
            break;
          case 'viirsPointToLayer':
            pointToLayer = viirsPointToLayer;
            if (format && format === 'viirs') {
              data = viirsToGeoJson(response.data);
            }
            break;
          default:
            break;
        }

        return {
          name,
          type,
          data,
          options: {
            ...options,
            pointToLayer
          }
        };
      };
    });
  }
}
