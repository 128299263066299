import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaChevronRight } from 'react-icons/fa';

import SidebarLayers from './SidebarLayers';
import SidebarSettings from './SidebarSettings';
import { formatDateTime } from '../lib/util';

const SidebarContainer = ({
  defaultVisible,
  date = {},
  event = {},
  getDataForLayers,
  setLensActiveRange,
  datapoints,
  ...rest
}) => {
  const [visibleSidebar, setVisibleSidebar] = useState(defaultVisible);
  const [isSidebarActive, setSidebarActive] = useState(true);

  const { date: dateRange } = date;
  const { start, end } = dateRange || {};

  function toggleSidebar () {
    setSidebarActive(!isSidebarActive);
  }

  return (
    <>
      <div className="sidebar-date-values">
        <span>
          <b>Start: </b>
          {`${start ? formatDateTime(start) : 'No start date selected'}`}
        </span>
        <span>
          <b>End: </b>
          {`${end ? formatDateTime(end) : 'No end date selected'}`}
        </span>
      </div>
      <div
        className={`sidebar sidebar-${visibleSidebar} ${
          isSidebarActive ? 'is-active' : ''
        }`}
      >
        {visibleSidebar === 'layers' && (
          <SidebarLayers
            {...rest}
            date={date}
            getDataForLayers={getDataForLayers}
            panel="layers"
            event={event}
            setVisibleSidebar={setVisibleSidebar}
            datapoints={datapoints}
          />
        )}
        {visibleSidebar === 'settings' && (
          <SidebarSettings
            event={event}
            setVisibleSidebar={setVisibleSidebar}
          />
        )}
      </div>
      <div
        className={`sidebar-collapse sidebar-open-toggle ${
          !isSidebarActive ? 'is-active' : ''
        }`}
      >
        <button onClick={() => toggleSidebar()}>
          <span className="visually-hidden">Toggle Sidebar</span>
          <FaChevronRight />
        </button>
      </div>
    </>
  );
};

SidebarContainer.propTypes = {
  defaultVisible: PropTypes.string,
  date: PropTypes.object,
  event: PropTypes.object,
  getDataForLayers: PropTypes.func,
  setLensActiveRange: PropTypes.func,
  datapoints: PropTypes.array
};

export default SidebarContainer;
