import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Layout from 'components/Layout';
import LensWrapper from './LensWrapper.js';

import Event from 'models/event';

import events from '../../static/data/events/config.json';

const EventLayout = ({ eventId, ...props }) => {
  // TODO: we should make some sort of request to get stored event data
  // TODO: figure out how we're dealing with timezones with the date picker
  const currEvent = new Event(
    events.filter(event => event.slug === eventId)[0]
  );

  const {
    name,
    defaultCenter,
    startDate,
    endDate,
    timezone,
    defaultZoom,
    maxZoom,
    minZoom,
    projection,
    layers,
    services,
    fetchLayerData
  } = currEvent;

  return (
    <Layout className="fullscreen">
      <Helmet bodyAttributes={{ class: 'page-event' }}>
        <title>{name}</title>
      </Helmet>
      <LensWrapper
        defaultZoom={defaultZoom}
        maxZoom={maxZoom}
        minZoom={minZoom}
        availableLayers={layers}
        availableServices={services}
        defaultCenter={defaultCenter}
        fetchLayerData={fetchLayerData}
        projection={projection}
        defaultDateRange={{
          start: startDate,
          end: endDate
        }}
        timezone={timezone}
        event={currEvent}
      />
    </Layout>
  );
};

EventLayout.propTypes = {
  eventId: PropTypes.string
};

export default EventLayout;
