import React, { useRef, useLayoutEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEqual } from 'lodash';

import enumerate from '../lib/enumerate';
import getWindowSize from '../lib/getWindowSize';
import { useDraggable } from 'hooks';

import ActiveRangeMarker from './ActiveRangeMarker';
import TimelineDefs from './TimelineDefs';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import HoverBox from './HoverBox';

const HOVER_BOX_DEFAULTS = {
  datapoint: {},
  show: false,
  xPosition: 0
};

const Timeline = ({
  startTime,
  endTime,
  data,
  duration: dur,
  onDrag,
  onDragEnd,
  setLensActiveRange,
  timezone
}) => {
  const enableDurationToggle = false;
  const timelineRef = useRef();
  const lineRef = useRef();

  const [duration, setDuration] = useState(dur);

  const range = enumerate(startTime, endTime, duration);

  const [windowSize, setWindowSize] = useState(getWindowSize);
  const [hoverBoxOptions, setHoverBoxOptions] = useState(HOVER_BOX_DEFAULTS);

  const [wrapperDimensions, setWrapperDimensions] = useState({
    width: 0
  });

  const useActiveRange = defaultState => {
    const [activeRange, setActiveRange] = useState(defaultState);

    const smartSetActiveRange = newState => {
      if (!isEqual(activeRange, newState)) {
        setActiveRange(newState);
      }
    };

    return [activeRange, smartSetActiveRange];
  };

  const [activeRange, setActiveRange] = useActiveRange({
    start: startTime.tz(timezone).valueOf(),
    end: endTime.tz(timezone).valueOf()
  });

  const [cellWidth] = useState(50);

  const [handleMouseDown, lineState, setLineState] = useDraggable({
    onDrag,
    onDragEnd,
    startingPosition: 0,
    minX: 0
  });

  function handleResize () {
    setWindowSize(getWindowSize());
  }

  // TODO: Check that setting lineState is nessesary below
  useLayoutEffect(() => {
    const lineWidth = lineState.width
      ? lineState.width
      : wrapperDimensions.width;

    setLineState(state => ({
      ...state,
      width: lineWidth
    }));

    setWrapperDimensions(timelineRef.current.getBoundingClientRect());

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [
    wrapperDimensions.width,
    windowSize,
    lineState.width,
    setLineState,
    range.length,
    duration
  ]);

  const timelineConfig = {
    timelineHeight: 40,
    timelineWidth: wrapperDimensions.width || 40
  };

  const dataPoints = data.map((entry, i) => {
    const { properties } = entry || {};
    const distanceFromStart = moment(properties.time).diff(
      startTime,
      'minutes'
    );
    const pxPerMin = cellWidth / 60;

    return {
      ...entry,
      key: distanceFromStart * pxPerMin
    };
  });

  const setTimelineDraggable = newState => {
    setLineState(state => ({
      ...state,
      isDraggable: newState
    }));
  };

  const onHandleDrag = useCallback(
    position => {
      let newEndTime;
      const startCopy = startTime.clone();

      if (duration === 'day') {
        const pxPerHour = cellWidth / (24 * 60);
        const dragXDistanceInHours = Math.floor(position.x / pxPerHour);
        newEndTime = startCopy.add(dragXDistanceInHours, 'minutes');
      } else if (duration === 'hour') {
        const pxPerMinute = cellWidth / 60;
        const dragXDistanceInMinutes = Math.floor(position.x / pxPerMinute);
        newEndTime = startCopy.add(dragXDistanceInMinutes, 'minutes');
      }

      setActiveRange(state => {
        let newEnd = state.end;

        if (newEndTime) {
          newEnd = newEndTime.valueOf();
        }

        return {
          ...state,
          end: newEnd
        };
      });
    },
    [cellWidth, duration, startTime, setActiveRange, setLensActiveRange]
  );

  const onHandleDragEnd = useCallback(
    position => {
      setTimelineDraggable(true);

      let newEndTime;
      const startCopy = startTime.clone();
      // TODO: Create a helper function for if/else statement here and in onHandleDrag
      if (duration === 'day') {
        const pxPerHour = cellWidth / (24 * 60);
        const dragXDistanceInHours = Math.floor(position.x / pxPerHour);
        newEndTime = startCopy.add(dragXDistanceInHours, 'minutes');
      } else if (duration === 'hour') {
        const pxPerMinute = cellWidth / 60;
        const dragXDistanceInMinutes = Math.floor(position.x / pxPerMinute);
        newEndTime = startCopy.add(dragXDistanceInMinutes, 'minutes');
      }

      setLensActiveRange(state => {
        let newEnd = state.end;

        if (newEndTime) {
          newEnd = newEndTime.valueOf();
        }

        return {
          ...state,
          end: newEnd
        };
      });
    },
    [cellWidth, duration, startTime, setLensActiveRange]
  );

  const onToggleDuration = useCallback(() => {
    setDuration(state => (state === 'hour' ? 'day' : 'hour'));
  }, []);

  let startOffset = 0;

  const buildRange = () => {
    return range.map((rangeItem, i) => {
      const rangeLabelObj = moment(rangeItem, 'HH:mm:ss');

      // Default the text label to HH:mm
      let rangeLabelText = moment(rangeLabelObj).format('HH:mm');

      if (duration === 'hour') {
        rangeLabelText = moment(rangeLabelObj).format('HH:mm');

        // If the rangeItem is the first in a given day, use MM-DD
        if (rangeLabelObj.format('HH:mm') === '00:00') {
          rangeLabelText = moment(rangeLabelObj).format('MM/DD');
        }
      } else if (duration === 'day') {
        // If in the days view, use the MM-DD format
        rangeLabelText = moment(rangeLabelObj).format('MM/DD');
      }

      let rangeWidth = cellWidth;

      const startOfRange = moment(rangeItem).clone();
      const endOfRange = moment(range[i + 1]).clone();
      const lessThanDuration = endOfRange.diff(
        startOfRange,
        duration === 'hour' ? 'minutes' : 'hours'
      );

      const divisions = duration === 'hour' ? 60 : 24;

      if (lessThanDuration < divisions) {
        rangeWidth = (lessThanDuration / divisions) * rangeWidth;
        startOffset += rangeWidth;
      }

      const startX = rangeWidth * i - startOffset;

      return (
        <g
          key={`${rangeItem.toString()}-group`}
          className="timeline__range-marker-group"
        >
          <rect
            className="timeline__range-marker"
            height={6}
            width={6}
            rx={3}
            x={startX - 1.5}
            y={timelineConfig.timelineHeight / 2 - 3}
            opacity="1"
          />
          {i === range.length - 1 && (
            <rect
              className="timeline__range-marker"
              height={6}
              width={6}
              rx={3}
              x={startX - 1.5}
              y={timelineConfig.timelineHeight / 2 - 3}
              opacity="1"
            />
          )}
          <text
            className="timeline__range-label"
            x={startX + 2}
            y={timelineConfig.timelineHeight - 5}
          >
            {rangeLabelText}
          </text>
          <rect
            className="timeline__range-marker-hightlight"
            height={timelineConfig.timelineHeight}
            width={rangeWidth}
            x={startX + 1}
            y={0}
            opacity="1"
          />
        </g>
      );
    });
  };

  return (
    <div className="timeline" ref={timelineRef}>
      <svg
        className="timeline__inner"
        preserveAspectRatio="xMidYMid slice"
        onMouseDown={handleMouseDown}
        clipPath="url(#timeline-clip)"
        viewBox={`0 0 ${timelineConfig.timelineWidth} ${timelineConfig.timelineHeight}`}
      >
        <TimelineDefs timelineConfig={timelineConfig} />
        <g>
          <rect
            id="timeline-timeline"
            className="timeline__timeline"
            height={timelineConfig.timelineHeight}
            width={timelineConfig.timelineWidth}
            rx={timelineConfig.timelineHeight / 2 - 1}
          />
          <line
            className="timeline__line"
            stroke="white"
            strokeWidth="1"
            x1="0"
            x2={wrapperDimensions.width}
            rx="1"
            y1={timelineConfig.timelineHeight / 2}
            y2={timelineConfig.timelineHeight / 2}
          />
          <g
            className="timeline__main-group"
            ref={lineRef}
            transform={`translate(${lineState.position.x}, 0)`}
          >
            <ActiveRangeMarker
              timelineConfig={timelineConfig}
              x="0"
              y="0"
              cellWidth={cellWidth}
              duration={duration}
              start={activeRange.start}
              end={activeRange.end}
              onHandleDrag={onHandleDrag}
              onHandleDragStart={() => {
                setTimelineDraggable(false);
              }}
              onHandleDragEnd={onHandleDragEnd}
              timezone={timezone}
            />
            {buildRange()}
            {dataPoints.map((datapoint, i) => {
              const { key } = datapoint;
              const xPosition = key - 4;

              function handleOnMouseEnter () {
                setHoverBoxOptions({
                  datapoint,
                  show: true,
                  xPosition: xPosition + lineState.position.x + 4
                });
              }
              function handleOnMouseLeave () {
                setHoverBoxOptions(HOVER_BOX_DEFAULTS);
              }
              return (
                <rect
                  key={i}
                  className="timeline__event-marker"
                  height={10}
                  width={10}
                  fill="#f44336"
                  stroke="#f56c50"
                  rx={5}
                  x={xPosition}
                  y={timelineConfig.timelineHeight / 2 - 5}
                  style={{ filter: 'url(#event-marker-shadow)' }}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
              );
            })}
          </g>
        </g>
      </svg>
      {enableDurationToggle && (
        <div className="timeline__end">
          <span className="timeline__duration-toggle-buttons">
            <button
              className="timeline__duration-toggle-button timeline__duration-toggle-button--up"
              type="button"
              onClick={onToggleDuration}
            >
              <FaCaretUp />
            </button>
            <button
              className="timeline__duration-toggle-button timeline__duration-toggle-button--down"
              type="button"
              onClick={onToggleDuration}
            >
              <FaCaretDown />
            </button>
          </span>
          <span className="timeline__duration-toggle">{`${duration}s`}</span>
        </div>
      )}
      <HoverBox {...hoverBoxOptions} />
    </div>
  );
};

Timeline.defaultProps = {
  data: [],
  duration: 'day',
  onDrag: undefined,
  onDragEnd: undefined
};

Timeline.propTypes = {
  startTime: PropTypes.shape({
    clone: PropTypes.func.isRequired,
    format: PropTypes.func,
    tz: PropTypes.func
  }).isRequired,
  endTime: PropTypes.shape({
    format: PropTypes.func,
    tz: PropTypes.func
  }).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  duration: PropTypes.string.isRequired,
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
  setLensActiveRange: PropTypes.func,
  timezone: PropTypes.string.isRequired
};

export default Timeline;
