export default class Datapoint {
  constructor (data) {
    Object.assign(this, {
      time: data.time || data.pubDate,
      type: data.type || data.category,
      reporter: data.reporter,
      // major incidents format
      title: data.title,
      link: data.link,
      guid: data.guid,
      metadata: Object.assign(
        {
          name: null,
          contact: null,
          species: null,
          notes: null
        },
        data.metadata
      )
    });
    this.name = this.metadata.name || data.title;
    this.contact = this.metadata.contact;
    this.species = this.metadata.species;
    this.notes = this.metadata.notes || data.description;
  }
}
