import React, { useState } from 'react';
import MapPopupContent from 'components/MapPopupContent';

export default function usePopup () {
  const [leafletState, setLeafletState] = useState({
    layer: {},
    showMarker: false
  });

  function handleOnCreated (layer, leafletElement) {
    setLeafletState({
      layer,
      showMarker: true
    });
    leafletElement.openPopup();
    window.addEventListener('datapointFormSubmit', () => {
      leafletElement.closePopup();
    });
    return true;
  }

  function hideMarker () {
    setLeafletState({
      ...leafletState,
      showMarker: false
    });
  }

  const PopupContent = props => {
    return (
      <MapPopupContent
        layer={leafletState.layer}
        hideMarker={hideMarker}
        {...props}
      />
    );
  };

  return {
    handleOnCreated,
    PopupContent,
    showMarker: leafletState.showMarker
  };
}
