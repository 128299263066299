import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Button } from 'fogg/ui';

import ClassName from 'models/classname';

const SidebarControls = ({ children, controls = [] }) => {
  const componentClassName = new ClassName('sidebar-controls');

  if (!Array.isArray(controls) || controls.length === 0) {
    return null;
  }

  return (
    <Panel className={componentClassName.string}>
      <ul>
        {controls.map((setting, index) => {
          const { label, icon, onClick } = setting;
          const key = `SidebarControls-item-${index}`;
          return (
            <li className={componentClassName.child('item')} key={key}>
              <p>
                <Button
                  type={['icon-before', 'text']}
                  full={true}
                  onClick={onClick}
                >
                  {icon} {label}
                </Button>
              </p>
            </li>
          );
        })}
      </ul>
      {children && (
        <div className={componentClassName.child('content')}>{children}</div>
      )}
    </Panel>
  );
};

SidebarControls.propTypes = {
  children: PropTypes.node,
  controls: PropTypes.array
};

export default SidebarControls;
