import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { useDatapoints } from 'hooks';
import { fetchDatapoints } from 'state/actions';

export default function useFetchDatapoints () {
  const datapoints = useDatapoints();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDatapoints()).catch(error => {
      throw new Error(error);
    });
  }, [dispatch]);

  return datapoints;
}
