import React from 'react';
import PropTypes from 'prop-types';

const TimelineDefs = ({ timelineConfig }) => (
  <defs>
    <clipPath id="timeline-clip">
      <rect
        height={timelineConfig.timelineHeight}
        width={timelineConfig.timelineWidth}
        rx={timelineConfig.timelineHeight / 2 - 1}
      />
    </clipPath>
    <filter
      id="event-marker-shadow"
      height="300%"
      width="300%"
      y="-100%"
      x="-100%"
    >
      <feGaussianBlur in="SourceAlpha" stdDeviation="5" />
      <feOffset dx="0" dy="0" result="offsetblur" />
      <feComponentTransfer>
        <feFuncA type="linear" slope="0.75" />
      </feComponentTransfer>
      <feMerge>
        <feMergeNode />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  </defs>
);

TimelineDefs.propTypes = {
  timelineConfig: PropTypes.shape({
    timelineHeight: PropTypes.number.isRequired,
    timelineWidth: PropTypes.number.isRequired
  }).isRequired
};

export default TimelineDefs;
