import L from 'leaflet';
import Datapoint from '../models/datapoint';

/**
 * queryParamsToObject
 * @description Takes the URL param string and turns it into an oobject
 */

export function queryParamsToObject (string) {
  if (typeof string !== 'string') return null;

  const queryString = string.replace('?', '');
  const querySplit = queryString.split('&');
  const queryObject = {};

  for (let i = 0, len = querySplit.length; i < len; i++) {
    const currentSplit = querySplit[i].split('=');
    queryObject[decodeURIComponent(currentSplit[0])] = decodeURIComponent(
      currentSplit[1]
    );
  }

  return queryObject;
}

/**
 * addParamsToUrl
 * @description takes a url and query param object and adds the params to the url
 */

export function addParamsToUrl (url, object, encodeComponents) {
  if (typeof url !== 'string' || typeof object !== 'object') return url;

  if (typeof encodeComponents === 'undefined') encodeComponents = true;

  const urlSplit = url.split('?');
  const urlBase = urlSplit[0];
  const urlSearch = urlSplit[1];
  let urlSearchObject = urlSearch ? queryParamsToObject(urlSearch) : {};
  const objectParams = {};
  let urlSearchObjectKeys = [];

  for (const key in object) {
    if (!Object.prototype.hasOwnProperty.call(object, key)) continue;
    if (typeof object[key] === 'undefined' || object[key] === null) continue;

    objectParams[key] = object[key];
  }

  urlSearchObject = Object.assign(urlSearchObject, objectParams);
  urlSearchObjectKeys = Object.keys(urlSearchObject);

  // If we don't have any url params, just pass back the URL as is

  if (urlSearchObjectKeys.length === 0) {
    return urlBase;
  }

  // Take the keys and map them into key value pairs into the string form

  return (
    urlBase +
    '?' +
    urlSearchObjectKeys
      .map(function (k) {
        if (encodeComponents) {
          return (
            encodeURIComponent(k) + '=' + encodeURIComponent(urlSearchObject[k])
          );
        }

        return k + '=' + urlSearchObject[k];
      })
      .join('&')
  );
}

/**
 * validateObjectKeysExist
 * @description Takes an obejct and set of keys and makes sure everything exists
 */

export function validateObjectKeysExist (data, keys = []) {
  return (
    keys.filter(key => {
      if (typeof key === 'undefined') return true;
      if (key === null) return true;
      return false;
    }).length > 0
  );
}

/**
 * filterObjectByKeys
 * @description Removes any property that doesnt exist in the allowed keys array
 */

export function filterObjectByAllowedKeys (object = {}, allowedKeys = []) {
  if (typeof object !== 'object') return {};
  if (!Array.isArray(allowedKeys)) return object;

  const newObject = {};

  for (const key in object) {
    if (!Object.prototype.hasOwnProperty.call(object, key)) continue;
    if (!allowedKeys.includes(key)) continue;
    newObject[key] = object[key];
  }

  return newObject;
}

/**
 * viirsToGeoJson
 * @description Converts the VIIRS json into GeoJSON
 */

export function viirsToGeoJson (data = '') {
  const validData = data.replace(/: ([\wa-zA-Z.-]+)/gm, ': "$1"');
  const json = JSON.parse(validData);
  const geoJson = {
    type: 'Feature Collection',
    features: json.map(entry => {
      const geoObj = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            parseFloat(entry.longitude),
            parseFloat(entry.latitude)
          ]
        },
        properties: {
          instrument: 'VIIRS'
        }
      };
      return geoObj;
    })
  };
  return geoJson;
}

/**
 * viirsPointToLayer
 * @description Creates the points for the VIIRS layers
 */

export function viirsPointToLayer (feature, latlng) {
  return L.circleMarker(latlng, {
    radius: 2,
    stroke: false,
    color: '#dc392d',
    fillOpacity: 1
  });
}

/**
 * insituPointToLayer
 * @description Creates the points for the in-situ layers
 */

export function insituPointToLayer (feature, latlng) {
  const { properties } = feature;
  const datapoint = new Datapoint(properties);
  const icon = L.divIcon({
    className: 'insitu-icon',
    iconSize: L.Point(5, 5),
    html: `<div class="map-point insitu">
        <div class="map-point-inner">
          <header class="map-point-header">
            <h2>${datapoint.type}</h2><span class="map-point-latlng">${
      latlng.lat
    }, ${latlng.lng}</span><br>
          </header>
          <div class="map-point-body">
            <span class="map-point-label">Name:</span> ${datapoint.name}<br>
            ${
              datapoint.species
                ? `<span class="map-point-label">Species:</span> ${datapoint.species}<br>`
                : ''
            }
          </div>
          <div class="map-point-footer">
            <span class="map-point-label">Notes:</span> ${datapoint.notes}<br>
            ${
              datapoint.contact
                ? `<span class="map-point-label">Contact:</span> ${datapoint.contact}<br>`
                : ''
            }
            <span class="map-point-label">Reported:</span> ${datapoint.time}<br>
            ${
              datapoint.reporter
                ? `<span class="map-point-label">Reporter:</span> ${datapoint.reporter}<br>`
                : ''
            }
          </div>
        </div>
        <div class="map-point-point"></div>
      </div>`
  });
  return L.marker(latlng, {
    icon
  });
}

export function fireBoundaryStyle (feature, latlng) {
  return {
    stroke: true,
    weight: 1,
    color: '#f45042',
    fillColor: '#f45042',
    fillOpacity: 0.5
  };
}

/**
 * formatDateTime
 * @description Given a timestamp input, returns a date in format MM/DD/YYYY HH:MM
 */

export function formatDateTime (dateTime) {
  let date = dateTime;

  if (!(date instanceof Date)) {
    date = new Date(dateTime);
  }

  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const yyyy = date.getFullYear();

  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };

  const time = date.toLocaleString('en-US', timeOptions);

  return (date = mm + '/' + dd + '/' + yyyy + ' ' + time);
}

/**
 * isDomAvailable
 * @description Checks to see if the DOM is available by checking the existence of the window and document
 * @see https://github.com/facebook/fbjs/blob/master/packages/fbjs/src/core/ExecutionEnvironment.js#L12
 */

export function isDomAvailable () {
  return (
    typeof window !== 'undefined' &&
    !!window.document &&
    !!window.document.createElement
  );
}
