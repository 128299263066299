import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { usePopup, useFetchDatapoints } from 'hooks';
import { Lens } from 'fogg/ui';
import { isEqual } from 'lodash';
import moment from 'moment';

import SidebarContainer from '../components/SidebarContainer';
import Timeline from '../components/Timeline';

const LensWrapper = ({
  availableLayers,
  availableServices,
  defaultCenter,
  defaultDateRange = {},
  timezone,
  defaultZoom,
  maxZoom,
  minZoom,
  fetchLayerData,
  projection = 'epsg3857',
  event
}) => {
  const { handleOnCreated, PopupContent, showMarker } = usePopup();
  const datapoints = useFetchDatapoints();
  const duration = 'hour';

  let defaultLensActiveRange = {};
  if (defaultDateRange && (defaultDateRange.start || defaultDateRange.end)) {
    defaultLensActiveRange = {
      start: moment(defaultDateRange.start).valueOf() || undefined,
      end: moment(defaultDateRange.end).valueOf() || undefined
    };
  }

  const useLensActiveRange = defaultState => {
    const [lensActiveRange, setLensActiveRange] = useState(defaultState);

    const smartSetLensActiveRange = newState => {
      if (!isEqual(lensActiveRange, newState)) {
        setLensActiveRange(newState);
      }
    };

    return [lensActiveRange, smartSetLensActiveRange];
  };

  const [lensActiveRange, setLensActiveRange] = useLensActiveRange(
    defaultLensActiveRange
  );

  const SidebarLayersPatch = props => {
    return (
      <SidebarContainer
        defaultVisible="layers"
        setLensActiveRange={setLensActiveRange}
        event={event}
        defaultDateRange={defaultDateRange}
        datapoints={datapoints}
        {...props}
      />
    );
  };

  return (
    <div data-show-marker={showMarker}>
      <Lens
        defaultZoom={defaultZoom}
        maxZoom={maxZoom}
        minZoom={minZoom}
        defaultCenter={defaultCenter}
        availableLayers={availableLayers}
        availableServices={availableServices}
        projection={projection}
        search={true}
        searchType="daterange"
        SidebarComponents={SidebarLayersPatch}
        hideNativeLayers={true}
        fetchLayerData={fetchLayerData}
        drawControlOptions={{
          circle: false,
          circlemarker: false,
          polyline: false,
          polygon: false,
          rectangle: false
        }}
        defaultDateRange={defaultDateRange}
        activeDateRange={lensActiveRange}
        onCreatedDraw={handleOnCreated}
        PopupContent={PopupContent}
      >
        <Timeline
          startTime={moment(defaultDateRange.start).tz(timezone)}
          endTime={moment(defaultDateRange.end).tz(timezone)}
          timezone={timezone}
          data={datapoints}
          duration={duration}
          setLensActiveRange={setLensActiveRange}
        />
      </Lens>
    </div>
  );
};

LensWrapper.propTypes = {
  availableLayers: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.shape({
      base: PropTypes.arrayOf(PropTypes.object),
      overlay: PropTypes.arrayOf(PropTypes.object)
    })
  ]),
  availableServices: PropTypes.arrayOf(PropTypes.object),
  fetchLayerData: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func)
  ]),
  defaultCenter: PropTypes.object,
  defaultDateRange: PropTypes.object,
  timezone: PropTypes.string,
  defaultZoom: PropTypes.number,
  maxZoom: PropTypes.number,
  minZoom: PropTypes.number,
  projection: PropTypes.string,
  event: PropTypes.object
};

export default LensWrapper;
