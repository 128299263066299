import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';
import { Panel, Modal } from 'fogg/ui';
import { useModal } from 'fogg/hooks';
import { FaCog, FaSync, FaPlusCircle } from 'react-icons/fa';

import Event from 'models/event';
import { insituPointToLayer } from 'lib/util';
import { addDatapoint } from 'state/actions';

import LayerList from 'components/LayerList';
import SidebarHeader from 'components/SidebarHeader';
import SidebarFooter from 'components/SidebarFooter';
import SidebarBody from 'components/SidebarBody';
import SidebarControls from 'components/SidebarControls';
import SidebarActions from 'components/SidebarActions';
import FormCreateDatapoint from 'components/FormCreateDatapoint';

const SidebarLayers = ({
  layers,
  toggleLayer,
  setVisibleSidebar,
  getDataForLayers,
  event = {},
  date = {},
  datapoints = []
}) => {
  const dispatch = useDispatch();
  const currEvent = new Event(event);
  const { name, logo, endDate: eventEndDate } = currEvent;

  const initialState = {
    modals: {
      createDatapoint: {
        isOpen: false
      }
    }
  };
  const {
    state,
    handleModalOpen,
    handleModalClose,
    ModalContextProvider
  } = useModal(initialState);

  const { date: dateRange } = date;
  const { start: startRange, end: endRange } = dateRange || {};

  const overlays = layers.overlay.map(layer => layer.name);

  useEffect(() => {
    if (overlays.includes('Reported Events')) {
      const startDate = startRange || 0;
      const endDate = endRange || Date.now();
      const filteredDatapoints = datapoints.filter(datapoint => {
        const { properties } = datapoint || {};
        const { time } = properties || {};
        const timestamp = new Date(time).getTime();
        return startDate <= timestamp && timestamp <= endDate;
      });
      const fetchFilteredInsituLayerData = () => {
        return {
          name: 'Reported Events',
          type: 'geojson',
          data: { features: filteredDatapoints },
          options: {
            pointToLayer: insituPointToLayer
          }
        };
      };

      getDataForLayers([fetchFilteredInsituLayerData]);
    }
  }, [datapoints, startRange, endRange]);

  const syncData = () => {
    const fetchInsituLayerData = () => {
      return {
        name: 'Reported Events',
        type: 'geojson',
        data: { features: datapoints },
        options: {
          pointToLayer: insituPointToLayer
        }
      };
    };

    getDataForLayers([fetchInsituLayerData]);
  };

  function handleDatapointSubmit (e, datapoint) {
    dispatch(addDatapoint(datapoint));
    e.target.reset();
    handleModalClose(e, 'createDatapoint');
  }

  const actions = [
    {
      label: 'Add Datapoint',
      icon: <FaPlusCircle />,
      modal: 'createDatapoint',
      onClick: handleModalOpen
    }
  ];

  const controls = [
    {
      label: 'Sync',
      icon: <FaSync />,
      onClick: syncData
    },
    {
      label: 'Settings',
      icon: <FaCog />,
      onClick: () => setVisibleSidebar('settings')
    }
  ];

  return (
    <ModalContextProvider>
      <SidebarHeader>
        <div className="content">
          {logo && <img src={logo} className="event-logo"></img>}
          <span className="event-name">{name}</span>
        </div>
        <Panel className="event-meta">
          <ul>
            {eventEndDate && (
              <>
                <li>{currEvent.formatDate(eventEndDate)}</li>
                <li className="time">
                  <time>
                    {currEvent.formatTime(eventEndDate)}{' '}
                    <span className="timezone">{eventEndDate.zoneAbbr()}</span>
                  </time>
                </li>
              </>
            )}
            {currEvent.duration && (
              <li>
                <span className="badge duration">
                  Duration:{' '}
                  <strong>{`${currEvent.duration.days()}d ${currEvent.duration.hours()}h`}</strong>
                </span>
              </li>
            )}
          </ul>
        </Panel>
      </SidebarHeader>
      <SidebarBody className="full no-padding">
        <Panel className="layers">
          {layers.overlay && (
            <LayerList
              layers={layers.overlay}
              heading="Overlay Layers"
              toggleLayer={toggleLayer}
            />
          )}
          {layers.base && (
            <LayerList
              layers={layers.base}
              heading="Base Layers"
              toggleLayer={toggleLayer}
            />
          )}
        </Panel>
      </SidebarBody>
      <SidebarFooter>
        <div className="sidebar-section">
          <SidebarActions actions={actions} />

          <SidebarControls controls={controls}>
            <p>Last sync: 1 hour ago</p>
          </SidebarControls>
        </div>
      </SidebarFooter>
      <Modal
        name="createDatapoint"
        closeText="Close Modal"
        handleCloseModal={handleModalClose}
        isOpen={state.modals.createDatapoint.isOpen}
        appElement="#___gatsby"
      >
        <FormCreateDatapoint onSubmit={handleDatapointSubmit} />
      </Modal>
    </ModalContextProvider>
  );
};

SidebarLayers.propTypes = {
  layers: PropTypes.object,
  toggleLayer: PropTypes.func,
  setVisibleSidebar: PropTypes.func,
  getDataForLayers: PropTypes.func,
  event: PropTypes.object,
  date: PropTypes.object,
  datapoints: PropTypes.array
};

export default SidebarLayers;
