const routes = [];

routes.push({
  name: 'home',
  path: '/'
});

routes.push({
  name: 'event',
  path: '/event/:eventId'
});

/**
 * Error Routes
 */

routes.push({
  name: 'errorNotFound',
  path: '/400/'
});

routes.push({
  name: 'errorServerError',
  path: '/500/'
});

export default routes;
