import React from 'react';
import PropTypes from 'prop-types';

const SidebarFooter = ({ children }) => (
  <footer className="sidebar-footer">{children}</footer>
);

SidebarFooter.propTypes = {
  children: PropTypes.node
};

export default SidebarFooter;
