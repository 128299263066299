import { Request } from 'fogg/models';

// TODO replace all requests with actual API calls

export const postDatapoint = () => {
  return async dispatch => {
    const request = new Request('/data/in-situ/in-situ-2.geojson');

    let response;
    try {
      response = await request.post();
    } catch (e) {
      throw new Error();
    }

    dispatch(addDatapoint(response.data));
  };
};

export const fetchDatapoints = () => {
  return async dispatch => {
    const request = new Request('/data/in-situ/in-situ-2.geojson');

    let response;

    try {
      response = await request.fetch();
    } catch (e) {
      throw new Error();
    }

    dispatch(updateDatapoints(response.data.features));
  };
};

/**
 * addDatapoint
 * @description Add a datapoint
 */

export const addDatapoint = data => {
  return {
    type: 'ADD_DATAPOINT',
    data
  };
};

/**
 * addDatapoints
 * @description Add a datapoint
 */

export const addDatapoints = data => {
  return {
    type: 'ADD_DATAPOINTS',
    data
  };
};

/**
 * updateDatapoints
 * @description update the datapoints
 */

export const updateDatapoints = data => {
  return {
    type: 'UPDATE_DATAPOINTS',
    data
  };
};
