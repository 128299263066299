import routes from 'data/routes';

export function accessByPath (path) {
  const currentRoute = routes.find(route => route.path === path);
  const { access } = currentRoute || {};
  return access;
}

export function routePathByName (name) {
  const route = routes.find(route => route.name === name);
  const { path } = route || {};
  return path;
}
