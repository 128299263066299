import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDraggable } from 'hooks';

const Handle = ({
  startingX,
  onClickHandle,
  onHandleDrag,
  onHandleDragEnd,
  onHandleDragStart,
  duration,
  ...rest
}) => {
  const [handleMouseDown, handleState, setHandleState] = useDraggable({
    onDrag: onHandleDrag,
    onDragEnd: onHandleDragEnd,
    onDragStart: onHandleDragStart,
    startingPosition: {
      x: startingX
    }
  });
  const { position } = handleState;
  const handleHeight = 10;

  useEffect(() => {
    if (startingX) {
      setHandleState(state => ({
        ...state,
        position: {
          ...state.position,
          x: startingX
        }
      }));
    }
  }, [startingX]);

  if (!position) return null;

  return (
    <polygon
      className="active-range-marker__handle"
      onMouseDown={e => {
        handleMouseDown(e);
      }}
      points={`
        ${position.x - handleHeight / 1.1},0 
        ${position.x}, ${handleHeight} 
        ${position.x + handleHeight / 1.1},0
      `}
      {...rest}
    />
  );
};

Handle.defaultProps = {
  onClickHandle: undefined,
  onHandleDrag: undefined,
  onHandleDragEnd: undefined,
  onHandleDragStart: undefined
};

Handle.propTypes = {
  duration: PropTypes.string.isRequired,
  startingX: PropTypes.number,
  onClickHandle: PropTypes.func,
  onHandleDrag: PropTypes.func,
  onHandleDragEnd: PropTypes.func,
  onHandleDragStart: PropTypes.func
};

const ActiveRangeMarker = ({
  cellWidth,
  timelineConfig,
  duration,
  start,
  end,
  onClickHandle,
  onHandleDrag,
  onHandleDragEnd,
  onHandleDragStart,
  timezone,
  ...rest
}) => {
  const range = moment(end)
    .tz(timezone)
    .diff(start, duration, true);
  return (
    <g className="active-range-marker">
      <rect
        fill="rgba(76, 118, 178, 0.4)"
        height={timelineConfig.timelineHeight}
        width={cellWidth * range}
        {...rest}
        x="0"
        y="0"
      />
      <line
        className="active-range-marker__line"
        stroke="rgba(148, 148, 148, 0.5)"
        strokeWidth="1"
        x1="0"
        x2={cellWidth * range}
        rx="1"
        y1={timelineConfig.timelineHeight / 2}
        y2={timelineConfig.timelineHeight / 2}
      />
      <line
        className="active-range-marker__line-cap"
        stroke="rgba(76, 118, 178, 0.75)"
        strokeWidth="1"
        x1={cellWidth * range}
        x2={cellWidth * range}
        rx="1"
        y1={0}
        y2={timelineConfig.timelineHeight}
      />
      <Handle
        startingX={cellWidth * range}
        fill="rgba(76, 118, 178, 1)"
        duration={duration}
        onClickHandle={onClickHandle}
        onHandleDrag={position => {
          onHandleDrag(position);
        }}
        onHandleDragStart={onHandleDragStart}
        onHandleDragEnd={position => {
          onHandleDragEnd(position);
        }}
        timezone={timezone}
      />
    </g>
  );
};

ActiveRangeMarker.defaultProps = {
  onHandleDrag: undefined,
  onHandleDragEnd: undefined,
  onHandleDragStart: undefined
};

ActiveRangeMarker.propTypes = {
  onHandleDrag: PropTypes.func,
  onHandleDragEnd: PropTypes.func,
  onHandleDragStart: PropTypes.func,
  cellWidth: PropTypes.number,
  timelineConfig: PropTypes.shape({
    timelineHeight: PropTypes.number
  }),
  duration: PropTypes.string.isRequired,
  start: PropTypes.number,
  end: PropTypes.number,
  onClickHandle: PropTypes.func,
  timezone: PropTypes.string.isRequired
};

export default ActiveRangeMarker;
