import React from 'react';
import PropTypes from 'prop-types';

const SidebarHeader = ({ children }) => (
  <header className="sidebar-header">{children}</header>
);

SidebarHeader.propTypes = {
  children: PropTypes.node
};

export default SidebarHeader;
