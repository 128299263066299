import React from 'react';
import { Router } from '@reach/router';
import { Button } from 'fogg/ui';

import { routePathByName } from 'lib/routes';

import EventLayout from 'components/EventLayout';

import events from '../../static/data/events/config.json';

const Events = () => {
  return (
    <ul className="link-list">
      {events.map((event, key) => {
        const { name, slug } = event;
        const to = `/event/${slug}`;
        return (
          <li key={`EventList-Event-${key}`}>
            <Button type="text" to={to}>
              {name}
            </Button>
          </li>
        );
      })}
    </ul>
  );
};

const EventPage = () => {
  return (
    <Router basepath="/">
      <EventLayout path={routePathByName('event')} />
      <Events default />
    </Router>
  );
};

export default EventPage;
