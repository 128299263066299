import React from 'react';
import PropTypes from 'prop-types';
import { snakeCase } from 'lodash';

import Toggle from 'components/Toggle';

const LayerToggle = ({ layer = {}, onClick } = {}) => {
  const { name, id, lastUpdated, isActive } = layer;

  function handleOnClick (e) {
    if (typeof onClick === 'function') {
      onClick(id);
    }
  }

  return (
    <div
      className={`layer-toggle layer-toggle-${snakeCase(name)} ${
        isActive ? 'is-active' : ''
      }`}
      onClick={handleOnClick}
    >
      <p className="layer-toggle-title">
        <strong>{name}</strong>
      </p>
      <p className="layer-toggle-meta">Updated: {lastUpdated}</p>
      <p className="layer-toggle-toggle">
        <Toggle isChecked={isActive} />
      </p>
    </div>
  );
};

LayerToggle.propTypes = {
  layer: PropTypes.object,
  onClick: PropTypes.func
};

export default LayerToggle;
