import React from 'react';
import PropTypes from 'prop-types';
import { FormRow, FormInput, Form, Button, ChildToggle } from 'fogg/ui';
import moment from 'moment';

const EMPTY_DATAPOINT = {
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: []
  },
  properties: {}
};

const FormCreateDatapoint = ({ layer = {}, onSubmit, ...rest }) => {
  const { center } = layer;
  const { lng: centerLng, lat: centerLat } = center || {};
  const defaultTime = moment().format('YYYY-MM-DDTHH:mm');

  function handleOnSubmit (e, fields = {}) {
    const {
      person,
      animal,
      time,
      reporter,
      lat,
      lng,
      name,
      contact,
      notes
    } = fields;
    const newDatapoint = { ...EMPTY_DATAPOINT };
    let eventType;

    let metadata = {
      name: name && name.value,
      contact: contact && contact.value,
      notes: notes && notes.value
    };

    // TODO: make this scalable for adding new types
    if (person && Array.isArray(person.value) && person.value.length > 0) {
      eventType = 'Missing Person';
    } else if (
      animal &&
      Array.isArray(animal.value) &&
      animal.value.length > 0
    ) {
      eventType = 'Missing Animal';
      metadata = {
        ...metadata,
        species: fields.species.value
      };
    }

    newDatapoint.geometry.coordinates = lng &&
      lat && [parseFloat(lng.value), parseFloat(lat.value)];
    newDatapoint.properties = {
      type: eventType,
      time: time.value,
      reporter: reporter.value,
      metadata
    };

    if (typeof onSubmit === 'function') {
      onSubmit(e, newDatapoint);
    }
  }

  const validationRules = {
    reporter: {
      required: true
    },
    time: {
      required: true
    }
  };

  return (
    <Form onSubmit={handleOnSubmit} rules={validationRules}>
      <div className="event-type">
        <h3>Event</h3>
        <FormRow className="flat-bottom">
          {/* TODO: make it so we can only select one type at a time */}
          <ChildToggle label="Missing Person" id="person" isField={true}>
            <FormInput id="name" label="Name" />
            <FormInput id="contact" label="Contact" />
            <FormInput id="notes" type="textarea" label="Notes" />
          </ChildToggle>
        </FormRow>
        <FormRow className="flat-bottom">
          <ChildToggle label="Missing Animal" id="animal" isField={true}>
            <FormInput id="name" label="Name" />
            <FormInput id="species" label="Species" />
            <FormInput id="contact" label="Contact" />
            <FormInput id="notes" type="textarea" label="Notes" />
          </ChildToggle>
        </FormRow>
      </div>
      <FormRow>
        <FormInput id="reporter" label="Reporter" type="text" required={true} />
      </FormRow>
      <FormRow>
        {/* Popup stops propagation of click events so we can't use react-datetime */}
        <FormInput
          id="time"
          type="datetime-local"
          label="Time of Event"
          defaultValue={defaultTime}
          required={true}
        />
      </FormRow>
      <FormRow>
        <FormInput
          id="lat"
          type="number"
          defaultValue={centerLat && centerLat.toPrecision(6)}
          label="Lat"
          required={true}
        />
        <FormInput
          id="lng"
          type="number"
          defaultValue={centerLng && centerLng.toPrecision(6)}
          label="Lng"
          required={true}
        />
      </FormRow>
      <FormRow></FormRow>
      <Button full={true}>Submit</Button>
    </Form>
  );
};

FormCreateDatapoint.propTypes = {
  layer: PropTypes.object,
  onSubmit: PropTypes.func
};

export default FormCreateDatapoint;
